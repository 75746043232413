import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';

interface AddCommentFormProps {
  onSuccess: () => void;
}

const API_URL = process.env.REACT_APP_API_URL;


const AddCommentForm: React.FC<AddCommentFormProps> = ({ onSuccess }) => {
  const { isAuthenticated, studentId, studentClass, studentEmoji, studentName } = useAuth();
  const [commentMessage, setCommentMessage] = useState('');
  const [rating, setRating] = useState(3); // Default rating is 1 star
  const [error, setError] = useState<string | null>(null);
  const handleRating = (value: number) => {
    setRating(value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const data = {
      student_id: studentId,
      student_name: studentName,
      comment_message: commentMessage,
      student_emoji: studentEmoji,
      student_class: studentClass,
      rating,
    };

    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      await axios.post(`${API_URL}/users/comments/create/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      onSuccess(); // Call the success callback (e.g., to refresh comments list)
    } catch (err) {
      setError('Failed to add comment. Please try again.');
    }
  };

  return (
    <>
    { isAuthenticated && studentId ? (
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 bg-white shadow-xl rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-700 text-center mb-6">Add Comment</h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}


        <div className="mb-4">
          <label className="block text-gray-600">Comment:</label>
          <textarea
            value={commentMessage}
            onChange={(e) => setCommentMessage(e.target.value)}
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
            required
          />
        </div>

        {/* <div className="mb-4">
          <label className="block text-gray-600">Rating:</label>
          <select
            value={rating}
            onChange={(e) => setRating(Number(e.target.value))}
            className="w-full p-3 border rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
            required
          >
            <option value={1}>⭐️</option>
            <option value={2}>⭐️⭐️</option>
            <option value={3}>⭐️⭐️⭐️</option>
            <option value={4}>⭐️⭐️⭐️⭐️</option>
            <option value={5}>⭐️⭐️⭐️⭐️⭐️</option>
          </select>
        </div> */}

        <div className="mb-4">
          <label className="block text-gray-600 mb-2">Rating:</label>
          <div className="flex space-x-1">
            {[1, 2, 3, 4, 5].map((star) => (
              <svg
                key={star}
                onClick={() => handleRating(star)}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill={star <= rating ? '#fcd34d' : 'none'} // Yellow if selected, unfilled otherwise
                stroke="#fcd34d" // Border color for stars
                className="w-8 h-8 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l2.196 6.742a1 1 0 00.95.69h7.043c.97 0 1.371 1.24.588 1.81l-5.705 4.147a1 1 0 00-.364 1.118l2.196 6.742c.3.921-.755 1.688-1.54 1.118l-5.705-4.147a1 1 0 00-1.176 0l-5.705 4.147c-.785.57-1.84-.197-1.54-1.118l2.196-6.742a1 1 0 00-.364-1.118L2.41 12.17c-.784-.57-.382-1.81.588-1.81h7.043a1 1 0 00.95-.69l2.196-6.742z"
                />
              </svg>
            ))}
          </div>
          <p className="text-gray-500 mt-2">Selected Rating: {rating} out of 5</p>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 rounded-lg transition duration-200"
        >
          Add Comment
        </button>
      </form>
  ) : (
  <>
    <h1 className="text-xl, text-black font-bold">Please <Link to={'/login'} className='text-white p-2 bg-black rounded-xl shadow-xl'>Login</Link> and Select Student to Add a Comment.</h1>
  </>)}
  </>
  );
};

export default AddCommentForm;
