import React, { useState } from 'react';

interface Question {
  id: number;
  question: string;
  options: string[];
}

interface Answer {
  id: number;
  answer: string;
}

const questionsData: Question[] = [
  {
    id: 1,
    question: "What is the capital of France?",
    options: ["Berlin", "Madrid", "Paris", "Lisbon"]
  },
  {
    id: 2,
    question: "Which planet is known as the Red Planet?",
    options: ["Earth", "Mars", "Jupiter", "Saturn"]
  },
  {
    id: 3,
    question: "What is the chemical symbol for water?",
    options: ["H2O", "O2", "CO2", "H2"]
  }
];

const answersData: Answer[] = [
  {
    id: 1,
    answer: "Paris"
  },
  {
    id: 2,
    answer: "Mars"
  },
  {
    id: 3,
    answer: "H2O"
  }
];

const QuizPage: React.FC = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [submitted, setSubmitted] = useState(false);
  const [reviewMode, setReviewMode] = useState(false);

  const currentQuestion = questionsData[currentQuestionIndex];
  const currentAnswer = answersData.find(answer => answer.id === currentQuestion.id)?.answer;

  const handleAnswer = (option: string) => {
    setUserAnswers(prev => ({ ...prev, [currentQuestion.id]: option }));
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setSubmitted(true);
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateResult = () => {
    let correctAnswers = 0;
    for (const [id, answer] of Object.entries(userAnswers)) {
      const correctAnswer = answersData.find(a => a.id === parseInt(id))?.answer;
      if (answer === correctAnswer) {
        correctAnswers += 1;
      }
    }
    return correctAnswers;
  };

  const totalAnswered = Object.keys(userAnswers).length;
  const totalNotAnswered = questionsData.length - totalAnswered;

  const getQuestionStatusColor = (questionId: number) => {
    return userAnswers[questionId] ? 'bg-green-500' : 'bg-red-500';
  };

  const getCardClass = (questionId: number) => {
    const userAnswer = userAnswers[questionId];
    const correctAnswer = answersData.find(a => a.id === questionId)?.answer;
    if (submitted) {
      return userAnswer === correctAnswer
        ? 'bg-green-100 border-green-500'
        : 'bg-red-100 border-red-500';
    }
    return '';
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
      <div className='w-full flex items-center justify-center'>
        <div className="text-sm flex text-white">
          <p className='p-2 bg-green-500 m-2 rounded-xl'>Answered: {totalAnswered}</p>
          <p className='p-2 bg-red-500 m-2 rounded-xl'>Not Answered: {totalNotAnswered}</p>
        </div>
      </div>
      <div className="bg-white shadow-lg rounded-xl p-6 max-w-xl w-full">
        {!submitted ? (
          <>
            <h2 className="text-2xl font-bold mb-4">{`Question ${currentQuestionIndex + 1}`}</h2>
            <p className="text-lg mb-6">{currentQuestion.question}</p>
            <ul className="mb-6">
              {currentQuestion.options.map((option, index) => (
                <li key={index} className="mb-2">
                  <button
                    onClick={() => handleAnswer(option)}
                    className={`w-full text-left py-2 px-4 rounded ${
                      userAnswers[currentQuestion.id] === option
                        ? 'bg-blue-700 text-white'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                    }`}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
            
            <div className="flex justify-between mb-6">
              <button
                onClick={prevQuestion}
                disabled={currentQuestionIndex === 0}
                className={`px-4 py-2 rounded ${
                  currentQuestionIndex === 0
                    ? 'bg-gray-300'
                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                }`}
              >
                Previous
              </button>
              <button
                onClick={nextQuestion}
                className={`px-4 py-2 rounded ${
                  currentQuestionIndex === questionsData.length - 1
                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                    : 'bg-blue-500 hover:bg-blue-600 text-white'
                }`}
              >
                {currentQuestionIndex === questionsData.length - 1 ? 'Submit' : 'Next'}
              </button>
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-bold mb-2">Question Status</h3>
              <div className="flex flex-wrap gap-4 mb-4">
                {questionsData.map((q) => (
                  <div
                    key={q.id}
                    className={`w-12 h-12 flex items-center justify-center text-white font-bold rounded ${getQuestionStatusColor(q.id)}`}
                  >
                    {q.id}
                  </div>
                ))}
              </div>
              
            </div>
          </>
        ) : (
          <div>
            {!reviewMode ? (
              <div className="bg-green-100 p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">Quiz Results</h2>
                <p className="text-lg mb-4">You answered {calculateResult()} out of {questionsData.length} questions correctly.</p>
                <button
                  onClick={() => setReviewMode(true)}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  Review Answers
                </button>
                <button
                  onClick={() => {
                    setUserAnswers({});
                    setCurrentQuestionIndex(0);
                    setSubmitted(false);
                    setReviewMode(false);
                  }}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ml-4"
                >
                  Try Again
                </button>
              </div>
            ) : (
              <div>
                <h2 className="text-2xl font-bold mb-4">Review Your Answers</h2>
                {questionsData.map((q) => {
                  const userAnswer = userAnswers[q.id];
                  const correctAnswer = answersData.find(a => a.id === q.id)?.answer;
                  return (
                    <div key={q.id} className={`p-4 border rounded-lg ${getCardClass(q.id)}`}>
                      <p className="font-bold">Question {q.id}:</p>
                      <p className="mb-2">{q.question}</p>
                      {q.options.map((option, index) => (
                        <p key={index} className={`py-1 px-2 rounded ${userAnswer === option ? (option === correctAnswer ? 'bg-green-200' : 'bg-red-200') : ''}`}>
                          {option}
                        </p>
                      ))}
                      <p className="font-bold mt-2">Correct Answer: {correctAnswer}</p>
                    </div>
                  );
                })}
                <button
                  onClick={() => {
                    setReviewMode(false);
                    setUserAnswers({});
                    setCurrentQuestionIndex(0);
                    setSubmitted(false);
                  }}
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mt-4"
                >
                  Back to Quiz
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizPage;
