import React, { useState, useEffect, useRef } from 'react';
import { getCourseDetails } from '../components/CourseDetailApiService';
import { Link, Navigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getYouTubeVideoId } from '../lib/getYouTubeVideoId'
import axios from 'axios';  // Import axios
import { Helmet } from 'react-helmet';

const JournalsIcon = () => <span>📓</span>;
const BookHalfIcon = () => <span>📖</span>;
const ChatLeftDotsIcon = () => <span>💬</span>;
const ClipboardCheckIcon = () => <span>✅</span>;
const LayoutWtfIcon = () => <span>🔗</span>;
const TestIcon = () => <span>📝</span>;

const YouTubeIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="100%" 
    fill="currentColor" 
    className="bi bi-youtube" 
    viewBox="0 0 16 16"
  >
    <path 
      d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"
    />
  </svg>
);

const API_URL = process.env.REACT_APP_API_URL;

interface User{
  first_name: string;
  last_name:string;
}

interface Teacher {
  user: User
}

interface Lecture {
  id: number;
  topic: string;
  description: string;
  is_unlocked: boolean;
  yt_link_ref: string;
  cnotes_ref: string | null;
  notes_ref: string | null;
  dpp_ref: string | null;
  is_locked: boolean;
  chapter: number;
  created_by: Teacher;
}

interface Chapter {
  id: number;
  title: string;
  // chapter_img: string | null;
  yt_link_ref: string | null;
  course: number;
  lectures: Lecture[];
}

interface CourseData {
  id: number;
  class_for: Array<{ name: string }>;
  teacher: {
    user: {
      username: string;
      first_name: string;
      last_name: string;
    };
    qualifications: string;
    subjects: string;
    experience: string;
  };
  course_name: string;
  description: string;
  course_img: string;
  mode: string;
  price: string;
  discount_percentage: string;
  chapters: Chapter[];
}


const LectureDisplay: React.FC<{ lecture: Lecture; lectureNum: number, chp_img:string  }> = ({ lecture, lectureNum, chp_img }) => {
  
  const videoId = lecture.yt_link_ref ? getYouTubeVideoId(lecture.yt_link_ref) :'BDQEwufOUY0'; // Replace with dynamic video ID if needed
  const thumbnailUrl = lecture.yt_link_ref ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : chp_img;
  // const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;

  const extractFileName = (filePath: string): string => {
    return filePath.replace('/media/', '').replace('.pdf', '');
  };
  

  return (
    <div className="bg-white rounded-xl shadow-md p-1 my-1">
      <div className="flex flex-col md:flex-row">
        <div>
          <Link to={`/VideoContent/${videoId}`}>
            <div className="relative w-full">
              <img
                src={thumbnailUrl}
                alt={lecture.topic}
                className="rounded-xl w-full h-full md:w-60 md:h-40 object-cover"
              />
              <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold  flex justify-start items-center space-x-4">
                Lecture {lectureNum + 1} 

                <div className=' text-red-600 w-6'>
                  <YouTubeIcon />
                </div>
              </div>
              
              {/* Transparent clickable overlay */}
              <div className="absolute inset-0 w-full h-full cursor-pointer"></div>
            </div>
          </Link>
          {/* <div className="relative w-full">
            <img
              src={thumbnailUrl}
              alt="lecture thumbnail"
              className="rounded-xl w-full md:w-60 h-48 md:h-40 object-cover"
            />
            <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold">
              Lecture {lectureNum + 1}
            </div>
          </div> */}
        </div>
        <div>
          <div className="p-2 lg:pl-8">
            <p className="text-lg font-bold">{lecture.topic}</p>
            <p className="text-lg">{lecture.description}</p>
          </div>
          <div className="w-full px-4 lg:px-8 flex gap-2 md:gap-4 justify-between">
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-blue-600 w-8 md:w-10">
              <JournalsIcon />
              <p className="text-sm text-black">cNotes</p>
            </Link>
            <Link to={lecture.notes_ref? `/pdf/${extractFileName(lecture.notes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-black w-10 md:w-12">
              <BookHalfIcon />
              <p className="text-sm text-black">xNotes</p>
            </Link>
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-black w-8 md:w-10">
              <ChatLeftDotsIcon />
              <p className="text-sm text-black">Doubts</p>
            </Link>
            <Link to={lecture.dpp_ref? `/pdf/${extractFileName(lecture.dpp_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-purple-700 w-10 md:w-12">
              <ClipboardCheckIcon />
              <p className="text-sm text-black">DPP</p>
            </Link>
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-purple-700 w-10 md:w-12">
              <TestIcon />
              <p className="text-sm text-black">Test</p>
            </Link>
            <Link to={lecture.cnotes_ref? `/pdf/${extractFileName(lecture.cnotes_ref)}`: '/pdf'} className="flex flex-col justify-start items-center text-2xl text-green-900 w-10 md:w-12">
              <LayoutWtfIcon />
              <p className="text-sm text-black">More</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const ExpandableSection: React.FC<{ chapter: Chapter, course_img: string }> = ({ chapter, course_img }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  // const chp_img = chapter.chapter_img ? `${API_URL}${chapter.chapter_img}` : 'https://i.ibb.co/yfQ6BxX/locked.jpg'
  // console.log(chp_img);
  const videoId = chapter.yt_link_ref ? getYouTubeVideoId(chapter.yt_link_ref) :'kRcnWWHiuIk'; // Replace with dynamic video ID if needed
  const thumbnailUrl = chapter.yt_link_ref ? `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg` : course_img;

  return (
    <div id={chapter.title} className="container mx-auto p-4 flex justify-center items-center">
      <div className="w-full md:max-w-2xl">
        <div ref={sectionRef} style={{ position: 'relative', cursor: 'pointer' }} className="my-1 rounded-xl bg-emerald-100 shadow-emerald-950 shadow-sm">
          <div className="rounded-xl shadow-emerald-100 shadow-md p-2 bg-emerald-50" onClick={toggleSection}>
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-60">
                <Link to={`/VideoContent/${videoId}`} className='w-full'>
                  <div className="relative w-full">
                    <img
                      src={thumbnailUrl}
                      alt={chapter.title}
                      className="object-cover w-full h-full md:h-40 rounded-xl"
                    />
                    <div className="absolute bottom-4 left-4 bg-white rounded-xl px-2 py-1 text-sm font-semibold  flex justify-start items-center space-x-4">
                      {chapter.yt_link_ref ? (<>Introduction Lecture</>) :(<>very soon intro</>) }
                      <div className=' text-red-600 w-6'>
                        <YouTubeIcon />
                      </div>
                    </div>
                    {/* Transparent clickable overlay */}
                    <div className="absolute inset-0 w-full h-full cursor-pointer"></div>
                  </div>
                </Link>
              </div>
              <div className="w-auto flex flex-col justify-start">
                <div className="pl-2 py-1 lg:pl-8">
                  <p className="text-lg text-wrap font-bold">{chapter.title}</p>
                </div>
                <div className='flex justify-start items-center'>
                <div className="w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
                  <div className="bg-white rounded-xl shadow-xl px-2 py-1 text-sm font-semibold">
                    {chapter.lectures.length} Lectures
                  </div>
                </div>
                <div className="relative bottom-0 mt-2 w-full px-4 lg:px-8 flex flex-wrap gap-2 md:gap-4 justify-between">
                  <div className="bg-green-500 rounded-xl px-2  py-1 text-sm text-white w-auto">
                    {isOpen ? 'Close' : 'Open Lectures'}
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="rounded-xl shadow-sm bg-emerald-100 p-1">
              {chapter.lectures.map((lecture, index) => (
                <LectureDisplay key={lecture.id} lecture={lecture} lectureNum={index} chp_img={thumbnailUrl} />
              ))}
              {chapter.lectures.length == 0 ? (<p className='text-center text-teal-600 text-lg font- font-semibold'>very soon lecture will be uploaded.</p>): (<></>)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Define the type for route parameters
interface CourseParams {
  courseId: string; // Use string if the ID is a string in the URL
}

const CoursePage: React.FC = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId ?? '', 10);
  const [courseData, setCourseData] = useState<CourseData | null>(null);
  const { studentId, enrolledCourses, authIntriger, setAuthIntriger } = useAuth();

  // Check if the current course is already enrolled
  const isEnrolled = enrolledCourses.includes(courseId);
  
  
  useEffect(() => {
    const fetchCourseData = async () => {
      try {        
        const data = studentId ? await getCourseDetails(courseId, studentId) :  await getCourseDetails(courseId);
        setCourseData(data);
        

      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourseData();
  }, [courseId, studentId]);


  const handleEnroll = async () => {
    const token = localStorage.getItem('token');
    if (discountedPrice == 0 ) {
    try {
      const response = await axios.post(
        `${API_URL}/courses/enrollments/`,
        {
          "student": studentId,
          "course": courseId
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if(response.status == 201){
        setAuthIntriger(authIntriger ? false : true);
      }
      alert('Enrollment successful!');
    } catch (error) {
      console.error('Error during enrollment:', error);
      alert('Failed to enroll. Please try again later.');
    }
  } else {
    // payment 
    alert('You have to pay first.')
  }
  };
    
  const discountedPrice = courseData ? (
    
    parseFloat(courseData.price) -
    (parseFloat(courseData.price) * parseFloat(courseData.discount_percentage)) / 100
    
  ) : 0;
  

  return (
    <div className="container mx-auto p-4">
      <Helmet>
            <title>{courseData?.course_name ? `${courseData.course_name} N A E COACHING` : "N A E COACHING"}</title>
            <meta name="description" content={courseData?.course_name ? `${courseData.course_name} N A E COACHING` : "Bihar board online and offline coaching for Science, Arts and Matric. Offline Coaching in Thakurganj, Kishanganj (Bihar)."} />
p
      </Helmet>
      {courseData ? (
        <>
          <div id={courseData.course_name} className="text-center my-4  flex flex-col lg:flex-row items-start">
            <img
              src={courseData.course_img || 'https://i.ibb.co/yfQ6BxX/locked.jpg'}
              alt={courseData.course_name}
              className="w-auto max-h-96 mx-auto rounded-xl"
            />
            <div className='flex justify-center w-full'> 
            <div className='relative bottom-0 bg-white self-center w-full  rounded-xl shadow-xl lg:shadow-sm py-4'>
            <h1 className="text-2xl font-bold my-2">{courseData.course_name}</h1>
            <p className="text-lg">{courseData.description}</p>
            
            {!isEnrolled && (
              <>
            <div className="text-lg font-semibold mt-2">
              Course Price: ₹{courseData.price}
            </div>
            <div className="text-lg font-semibold mt-1">
              <span className='text-2xl font-bold text-red-400'>{courseData.discount_percentage}% Offer</span> 
            </div>
            <div className="text-lg font-semibold mt-1">
              Pay <span className='text-3xl font-bold text-purple-500'>₹{discountedPrice}</span> only
            </div>
            <div className="text-lg font-semibold mt-1">
              Mode: {courseData.mode}
            </div>


            {/* Add  Enrollment Button  */}
             {/* Enrollment Button */}

             <button
                  onClick={handleEnroll}
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl shadow-lg transition duration-300 ease-in-out"
                >
                  Enroll Now
              </button>
              </>
              )}
            </div>
            </div>
          </div>

          <div className="my-4">
            {courseData.chapters.map(chapter => (
              <ExpandableSection  key={chapter.id} chapter={chapter} course_img={courseData.course_img} />
            ))}
            {courseData.chapters.length == 0 ? (<p className='text-center text-teal-600 text-lg font- font-semibold'>very soon chapters will be uploaded.</p>): (<></>)}

          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default CoursePage;
