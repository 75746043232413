import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; 
import ShimmerButton from "../components/magicui/shimmer-button";
import { log } from 'console';

interface UserData {
  id: number;
  name: string;
  student_class: string;
  emoji: string;
}

const classesData = [
  { displayName: 'CLASS  6', value: 'CLASS 6' },
  { displayName: 'CLASS  7', value: 'CLASS 7' },
  { displayName: 'CLASS  8', value: 'CLASS 8' },
  { displayName: 'CLASS  9', value: 'CLASS 9' },
  { displayName: 'CLASS  10', value: 'Class10' },
  { displayName: 'SCIENCE  11', value: 'SCIENCE 11' },
  { displayName: 'SCIENCE  12', value: 'SCIENCE 12' },
  { displayName: 'ARTS  11', value: 'ARTS 11' },
  { displayName: 'ARTS  12', value: 'ARTS 12' },
  // { displayName: 'DELED', value: 'DELED' },
  // { displayName: 'CTED', value: 'CTED' }
];

const Users = () => {
  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [newUser, setNewUser] = useState<Omit<UserData, 'id'>>({
    name: '',
    student_class: 'CLASS 6',
    emoji: '😊',
  });
  const [activeUserId, setActiveUserId] = useState<number | null>(null); // State for active user
  const { isAuthenticated, logout, studentId, studentClass, studentName, studentEmoji, setStudentEmoji, setStudentClass, setStudentId, setStudentName, authIntriger, setAuthIntriger } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch user data from backend on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error("Token not found");
        }

        const response = await axios.get(`${API_URL}/users/students/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        

        // Ensure the response data is an array
        if (Array.isArray(response.data)) {
          // console.log(response.data);
          
          setUsersData(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchData();
  }, []);

  const handleAddUser = () => {
    setShowForm(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error("Token not found");
      }

      await axios.post(`${API_URL}/users/students/`, newUser, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const response = await axios.get(`${API_URL}/users/students/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (Array.isArray(response.data.results)) {
        setUsersData(response.data.results);
      } else {
        console.error('Unexpected response format:', response.data);
      }
      
      setShowForm(false);
      setNewUser({ name: '', student_class: '', emoji:'' });
    } catch (error) {
      console.error('Error creating student:', error);
    }
  };
 

  const handleUserClick = (StudentId: number, StudentName: string, StudentClass: string, StudentEmoji: string) => {
    console.log(StudentId, StudentName, StudentClass, StudentEmoji);
    
    // Set the student details in the context
    setStudentId(StudentId);
    setStudentClass(StudentClass);
    setStudentName(StudentName);
    setStudentEmoji(StudentEmoji);

    
    // Store the active student details in localStorage
    const activeUser = {
      studentId: StudentId,
      studentName: StudentName,
      studentClass: StudentClass,
      studentEmoji: StudentEmoji,
    };
    localStorage.setItem('naeActiveStudent', JSON.stringify(activeUser));
    setActiveUserId(StudentId)
    setAuthIntriger(authIntriger ? false : true);
    // Navigate to the home page
    navigate('/');
  };

  return (
    <div className='h-screen'>
      <div className='h-2/3'>
        <div className="flex flex-wrap gap-4 items-center justify-center">
          {usersData.map((user) => (
            <div key={user.id} onClick={() => handleUserClick(user.id, user.name, user.student_class, user.emoji)} >
              <div  className={`rounded-full w-24 h-24 flex flex-col items-center justify-center bg-gray-50 overflow-hidden ${activeUserId === user.id ? 'border-2 border-purple-500' : ''}`}>
                {/* <img
                  src='assets/naescilogo.jpeg'
                  className="object-cover"
                  alt={user.name}
                /> */}
                <div className="text-7xl">{user.emoji}</div>
              </div>
              <div className="flex flex-col items-center">
                <span className="font-bold">{user.name}</span>
                <span className="text-sm">{user.student_class}</span>
              </div>
            </div>
          ))}

          <div className='flex flex-col'>
            <div
              className="rounded-full w-24 h-24 flex items-center justify-center bg-gray-200 cursor-pointer"
              onClick={handleAddUser}
            >
              <span className="text-3xl font-bold">+</span>
            </div>
            <h2 className='font-bold text-center'>Add <br />new Student</h2>
          </div>

          {showForm && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-md p-6 w-96">
                <h2 className="text-xl font-bold mb-4">Add New User</h2>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={newUser.name}
                    onChange={handleInputChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="className" className="block text-gray-700 font-bold mb-2">
                    Class
                  </label>
                  <select
                    id="className"
                    name="student_class" // Adjusted to match serializer field name
                    value={newUser.student_class}
                    onChange={handleInputChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    {classesData.map((classData, index) => (
                      <option key={index} value={classData.value}>
                        {classData.displayName}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='w-full py-5 flex items-center justify-center '>
        <div className="z-10 flex  items-center justify-center">
          <ShimmerButton onClick={handleLogout} className="shadow-2xl">
            <span className="whitespace-pre-wrap text-center text-m font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
              <button className="hover:text-purple-600 flex gap-4 items-center justify-center">Logout<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path fillRule="evenodd" d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
              </svg>
              </button>
            </span>
          </ShimmerButton>
        </div>
      </div>
    </div>
  );
};

export default Users;
