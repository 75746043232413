// src/components/InstallModal.tsx
import React, { useEffect, useState } from 'react';

const InstallModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlay, setIsplay] = useState(true);
  const [isInstallable, setIsInstallable] = useState(false);
  

  useEffect(() => {
    const lastClosed = localStorage.getItem('installModalClosed');
    const isAppInstalled = localStorage.getItem('appInstalled');
    const thirtyMinutesAgo = Date.now() - 30 * 60 * 1000;

    // Check if the modal should be shown (not installed and last closed over 30 minutes ago)
    if (!isAppInstalled && (!lastClosed || parseInt(lastClosed) < thirtyMinutesAgo)) {
      setIsVisible(true);
    }

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsVisible(true); // Show modal when app is installable
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('installModalClosed', Date.now().toString());
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          localStorage.setItem('appInstalled', 'true'); // Set app as installed
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsVisible(false); // Hide the modal after install prompt is handled
      });
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center z-50">
      {/* Close Button in Top Right Corner */}
      <div className=" w-full max-w-[500px] flex justify-end px-4"
      >
        <p className='text-white bg-black'>बंद करें</p>
      <button
          onClick={handleClose}
          className=" text-red-500 font-bold bg-gray-300 rounded-full px-3 hover:bg-white"
        >
          &times; Close Ad
        </button>
        </div>
      {/* Square Box for Video with Responsive Width */}
      <div className="relative w-full max-w-[500px] bg-white rounded-lg shadow-lg mx-4 md:mx-0 aspect-square overflow-hidden">
        {/* YouTube Video Background */}
        <iframe
          className="absolute inset-0 w-full h-full"
          src={`https://www.youtube.com/embed/mcjLGFSWTLo?autoplay=1&mute=0&loop=1&playlist=mcjLGFSWTLo`}
          title="YouTube video"
          frameBorder="0"
          allow="autoplay; encrypted-media"
        ></iframe>
        {/* Overlay to Disable Interaction */}
        <div className="absolute inset-0  pointer-events-none"></div>


      </div>
      <button
          onClick={handleInstallClick}
          className="relative bottom-5 px-4 py-2 bg-blue-500 text-white rounded-full border-2 hover:bg-blue-600"
        >
          Install <span className="text-xl font-bold text-black">NAE</span> App
        </button>
        <p className='text-white bg-black'>एप इनस्टॉल करने के लिए ऊपर बटन क्लिक करें</p>
    </div>
  );
};

export default InstallModal;
