import ContinueCourse from '../components/ContinueCourses';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const YourEnrolledCoursesComponent: React.FC = () => {
  const { isAuthenticated, studentId, studentClass } = useAuth();
  const [yourEnrolledCourses, setYourEnrolledCourses] = useState<any[]>([]);


  const fetchYourEnrolledCourses = async () => {
    const cachedYourEnrolledCourses = localStorage.getItem(`your${studentId}EnrolledCourses`);
    const yourEnrolledCoursesExpiry = localStorage.getItem(`your${studentId}EnrolledCoursesExpiry`);
    const now = new Date();
    const token = localStorage.getItem('token');

    if (cachedYourEnrolledCourses && yourEnrolledCoursesExpiry && now < new Date(yourEnrolledCoursesExpiry)) {
      return JSON.parse(cachedYourEnrolledCourses);
    } else {
      try {
        const response = await axios.get(`${API_URL}/courses/enrollments/student/${studentId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        console.log(response);
        
  
        const courseIds = response.data.map((enrollment: any) => enrollment.course);
        console.log(courseIds);
        
        const courseDetails = await Promise.all(
          courseIds.map(async (courseId: number) => {
            const courseResponse = await axios.get(`${API_URL}/courses/course-details/${courseId}/`, {
              headers: {
                Authorization: `Token ${token}`,
              },
            });
            return courseResponse.data;
          })
        );
  
        // Cache the course details and set an expiry time (e.g., 1 day)
        localStorage.setItem(`your${studentId}EnrolledCourses`, JSON.stringify(courseDetails));
        localStorage.setItem(`your${studentId}EnrolledCoursesExpiry`, new Date(now.getTime() + 24 * 60 * 60 * 1000).toISOString());
  
        return courseDetails;
      } catch (error) {
        console.error('Error fetching Enrolledcourses:', error);
        return null;
      }
    }
  };


  useEffect(() => {
    if (isAuthenticated && studentId) {
      if (studentId) {
        fetchYourEnrolledCourses().then((Enrolledcourses) => {
          if (Enrolledcourses) {
            setYourEnrolledCourses(Enrolledcourses);
          }
        });
      }
    }
  }, [isAuthenticated, studentId]);

  return (
    
    <div className='text-center'>
      <h1 className="text-2xl lg:text-3xl font-bold mb-4">Enrolled Courses</h1>
      {yourEnrolledCourses.length > 0 ? (
                <ContinueCourse courses={yourEnrolledCourses}  />

      ) : (
        <p className="text-2xl lg:text-3xl font-bold mb-4 text-red-300">आपने किसी कोर्स में <span className='text-black'>Enroll</span> नहीं किया है | Go to <Link to={'/courses'} className='text-green-400'>Courses</Link> page. </p>
      )} 
    </div>
  );
};




const Progress: React.FC = () => {
  const { isAuthenticated, studentId, studentClass } = useAuth();

  return (
    <div>
      <div>
        {isAuthenticated ? (
          studentId ? (
            <YourEnrolledCoursesComponent />
          ) : (
            <h1 className="text-2xl lg:text-3xl font-bold mb-4 text-center"> You've not select Student from student page, please select Students from <Link to={'/usersPage'} className='text-red-400'>User Page</Link>.</h1>
          )
        ) : (
          <div className="text-2xl lg:text-3xl font-bold mb-4 text-center">You are not Logged in. Please <Link to={'/login'} className='text-red-400'>Log in</Link> to see your progess.</div>
        )}

      </div>

      
      
    </div>
  );
};

export default Progress;
